// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "jquery";
//import "../vendor/jquery";
//require('jquery');
require('jquery-ui');
//require("bootstrap");

import "@yaireo/tagify";
//import "bootstrap";
import "chart.js";
import "datatables";
import "datatables.net-buttons";
import "daterangepicker";
import "daterangepicker/daterangepicker.css";
import "jquery-mask-plugin";
import "sortablejs";
//import "jquery-toast-plugin"
// import the bootstrap javascript module
import "bootstrap"

var jQuery = require('jquery')
window.jQuery = $;
window.$ = $;
global.Rails = Rails;

import { Dropzone } from "dropzone";
global.Dropzone = Dropzone;

require("../vendor/plugins");
require("../vendor/functions");

jQuery(function() { 
    $(".okewa-fc_icon").on("click", gotoWhatsApp);


    function gotoWhatsApp() {
        var num = '528714823382';
        var msg = 'Hola,%20estoy%20contactando%20desde%20el%20sitio%20web.'
        window.open('https://api.whatsapp.com/send?phone=' + num + '&text=' + msg, '_blank');
    }
});